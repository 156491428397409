import Vue from 'vue';
import {
    Button,
    NavBar,
    List,
    Empty,
    Cell,
    CellGroup,
    Icon,
    Col,
    Row,
    Tabbar,
    TabbarItem,
    Form,
    Field,
    Step,
    Steps,
    Checkbox,
    CheckboxGroup,
    Dialog,
    Sidebar,
    SidebarItem,
    Tabs,
    Tab,
    DropdownMenu,
    DropdownItem,
    RadioGroup,
    Popup,
    Popover,
    Switch,
    Radio,
    Swipe,
    SwipeItem,
    PullRefresh,
    Picker,
    CountDown,
    AddressEdit,
    Area,
    Loading,
    Collapse,
    CollapseItem,
    Uploader,
    Sticky,
    ImagePreview,
    Slider
} from 'vant';

import { Image as VanImage } from 'vant';

Vue.use(Button)
.use(Slider)
.use(NavBar)
.use(List)
.use(Empty)
.use(Cell)
.use(CellGroup)
.use(Row)
.use(Col)
.use(Tabbar)
.use(TabbarItem)
.use(Form)
.use(Field)
.use(Icon)
.use(Step)
.use(Steps)
.use(Checkbox)
.use(Dialog)
.use(Sidebar)
.use(SidebarItem)
.use(Tabs)
.use(Tab)
.use(DropdownMenu)
.use(DropdownItem)
.use(RadioGroup)
.use(Radio)
.use(Popup)
.use(Popover)
.use(Switch)
.use(CheckboxGroup)
.use(SwipeItem)
.use(Swipe)
.use(PullRefresh)
.use(VanImage)
.use(CountDown)
.use(Picker)
.use(AddressEdit)
.use(Area)
.use(Loading)
.use(Collapse)
.use(CollapseItem)
.use(Uploader)
.use(ImagePreview)
.use(Sticky);
