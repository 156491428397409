
export default {
    state: {
      saEnUserInfo:{
        name:""
      },
    },
    getters: {
        getsaEnUserInfo:function(state){
           if(!state.saEnUserInfo){
               state.saEnUserInfo = JSON.parse(localStorage.getItem('saEnUserInfo'));
           }
           return state.saEnUserInfo;
        }
    },
    mutations: {
      setsaEnUserInfo(state,res){
        state.saEnUserInfo= res;
        localStorage.setItem('saEnUserInfo',JSON.stringify(res));
      }
    },
    actions: {
        
    }
} 