export default {
    state: {
        direction:{
            // firstId:'137',
            // learningName:'初级会计师',
            firstId:'',
            learningName:'全部课程'
        }
    },
    getters: {
        getDirection: (state) => state.direction,
    },
    mutations: {
        setDirection(state,data){
            state.direction.firstId = data.firstId
            state.direction.learningName = data.learningName
        }
    }
};
