<template>
  <div id="app">
    <router-view/>
    <img v-if="isHome" src="@/assets/all_Icon/drawable-xhdpi/home.jpg.webp" class="goHome" @click="goHome">
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHome: false
    };
  },
  watch: {
    $route(to) {
      if(to.path === '/'||to.path == '/login'||to.path === '/fuli' || to.name == 'videoPage' || to.name=='ke' || to.name=='learning') {
        this.isHome = false;
      }else {
        this.isHome = false;
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 100%;
  height: 100%;
}
html,body{
    width: 100%;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.goHome{
  position: fixed;
  bottom: 62px;
  right: 10px;
  font-size: 28px;
  background: #f8f8f8;
  box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.07);
  width: 34px;
  height: 34px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.photoList{
  z-index: 10000000000 !important;
}
</style>
