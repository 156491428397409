import Vue from "vue";
import VueRouter from "vue-router";


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
    { //首页
        path: "/",
        name: "home",
        component: resolve => require(['@/pages/home/index.vue'], resolve),
        meta:{
            title:'萨恩课堂-中国会计网',
            keepAlive:false
        }
    },
    { //登录
        path: "/login",
        name: "login",
        component: resolve => require(['@/pages/login/index.vue'], resolve),
        meta:{
            title:'登录',
        }
    },
    { //登录
        path: "/test",
        name: "test",
        component: resolve => require(['@/pages/login/test.vue'], resolve),
    },
    { //重置密码
        path: "/retpassword",
        name: "retpassword",
        component: resolve => require(['@/pages/login/retpassword.vue'], resolve),
        meta:{
            title:'重置密码',
        }
    },


    { //首页搜索
        path: "/search",
        name: "search",
        component: resolve => require(['@/pages/home/searchpage.vue'], resolve),
        meta:{
            title:'首页搜索',
        }
    },
    { //首页搜索结果
        path: "/searchresult",
        name: "search",
        component: resolve => require(['@/pages/home/searchresult.vue'], resolve),
        meta:{
            title:'首页搜索',
        }
    },
    { //首页直播列表
        path: "/homeLiveList",
        name: "homeLiveList",
        component: resolve => require(['@/pages/home/homeLiveList.vue'], resolve),
        meta:{
            title:'直播列表',
        }
    },
    { //首页点击宫格-跳往的页面
        path: "/class/:firstId?",
        name: "homeGrid",
        component: resolve => require(['@/pages/home/homeGrid.vue'], resolve),
        meta:{
            title:'课程中心',
        }
    },


    { //选课
        path: "/xuanke",
        name: "xuanke",
        component: resolve => require(['@/pages/selectClass/selectClass.vue'], resolve),
        meta:{
            title:'选课',
        }
    },
    { //选课-课程详情
        path: "/ke/:coursePackageId?",
        name: "ke",
        component: resolve => require(['@/pages/classCenter/classCenter.vue'], resolve),
        meta:{
            title:'选课中心',
            keepAlive:false
        }
    },
    { //选课-选课中心-视频播放页
        // path: "/videoPage/:coursePackageId?/:courseId?/:classHourId?/:videoId?",
        path: "/videoPage/:coursePackageId?/:courseId?/:classHourId?",
        name: "videoPage",
        component: resolve => require(['@/pages/videoPage/videoPage.vue'], resolve),
        meta:{
            title:'视频播放',
        }
    },
    { //视频播放页  新增笔记、编辑笔记页面
        path: "/addNotePage",
        name: "addNotePage",
        component: resolve => require(['@/pages/videoPage/addNotePage.vue'], resolve),
    },
    { //视频播放页  答疑/提问页面
        path: "/answerPage",
        name: "answerPage",
        component: resolve => require(['@/pages/videoPage/answerPage.vue'], resolve),
    },
    { //视频播放页  查看答疑
        path: "/classDaYi",
        name: "classDaYi",
        component: resolve => require(['@/pages/videoPage/classDaYi.vue'], resolve),
    },
    { //测试页面aa
        path: "/aa/:one?",
        name: "aa",
        component: resolve => require(['@/pages/aa.vue'], resolve),
    },


    { //study强-伟模块
        path: "/tiku",
        name: "tiku",
        component: resolve => require(['@/pages/study/questionBank.vue'], resolve),
        meta:{
            title:'题库',
        }
    },
    { //照片制作模块
        path: "/photo",
        name: "phtoto",
        component: resolve => require(['@/pages/codePhoto/index.vue'], resolve),
        meta:{
            title:'照片制作',
        }
    },
    { //照片制作类型列表
        path: "/codePhotoList",
        name: "codePhotoList",
        component: resolve => require(['@/pages/codePhoto/photoTypeList.vue'], resolve),
        meta:{
            title:'照片制作',
        }
    },
    { //我的证件照
        path: "/myPhoto",
        name: "myPhoto",
        component: resolve => require(['@/pages/codePhoto/myPhoto.vue'], resolve),
        meta:{
            title:'照片制作',
        }
    },
    { //我的证件照-详情
        path: "/photoDetail",
        name: "photoDetail",
        component: resolve => require(['@/pages/codePhoto/photoDetail.vue'], resolve),
        meta:{
            title:'照片制作',
        }
    },
    { //我的证件照-详情
        path: "/shotPhoto",
        name: "shotPhoto",
        component: resolve => require(['@/pages/codePhoto/shotPhoto.vue'], resolve),
        meta:{
            title:'照片制作',
        }
    },
    { //证件照
        path: "/generate",
        name: "generate",
        component: resolve => require(['@/pages/codePhoto/generate.vue'], resolve),
        meta:{
            title:'证件照',
        }
    },
    { //保存证件照
        path: "/savePhoto",
        name: "savePhoto",
        component: resolve => require(['@/pages/codePhoto/savePhoto.vue'], resolve),
        meta:{
            title:'证件照',
        }
    },
    { //支付证件照
        path: "/payment",
        name: "payment",
        component: resolve => require(['@/pages/codePhoto/payment.vue'], resolve),
        meta:{
            title:'证件照',
        }
    },
    { //下载证件照
        path: "/downloadPhoto",
        name: "downloadPhoto",
        component: resolve => require(['@/pages/codePhoto/downloadPhoto.vue'], resolve),
        meta:{
            title:'下载证件照',
        }
    },
    { //历年真题
        path: "/chapter",
        name: "chapter",
        component: resolve => require(['@/pages/study/chapter/chapterList.vue'], resolve),
        meta:{
            title:'历年真题',
        }
    },
    { //历年真题 -- 答题组件
        path: "/chapterDetail",
        name: "chapterDetail",
        component: resolve => require(['@/pages/study/chapter/chapterDetail.vue'], resolve),
        meta:{
            title:'答题',
        }
    },
    { //历年真题 -- 答题三级页面
        path: "/chapterQuestionDetail",
        name: "chapterQuestionDetail",
        component: resolve => require(['@/pages/study/chapter/chapterQuestionDetail.vue'], resolve),
        meta:{
            title:'历年真题',
        }
    },
    { //考前押题
        path: "/examination_questions",
        name: "examination_questions",
        component: resolve => require(['@/pages/study/chapter/examination_questions.vue'], resolve),
        meta:{
            title:'考前押题',
        }
    },
    { //考前押题 -- 答题三级页面
        path: "/examination_questions_detail",
        name: "examination_questions_detail",
        component: resolve => require(['@/pages/study/chapter/examination_questions_detail.vue'], resolve),
        meta:{
            title:'考前押题',
        }
    },
    { //模拟试卷
        path: "/simulated_paper",
        name: "simulated_paper",
        component: resolve => require(['@/pages/study/chapter/simulated_paper.vue'], resolve),
        meta:{
            title:'模拟试卷',
        }
    },
    { //模拟试卷 -- 答题三级页面
        path: "/simulated_paper_detail",
        name: "simulated_paper_detail",
        component: resolve => require(['@/pages/study/chapter/detail.vue'], resolve),
        meta:{
            title:'模拟试卷',
        }
    },
    { //错题本
        path: "/wrongquestionbook",
        name: "wrongquestionbook",
        component: resolve => require(['@/pages/study/questionbook/wrong_question_book.vue'], resolve),
        meta:{
            title:'错题本',
        }
    },
    { //错题本 -- 答题三级页面
        path: "/wrongquestionbookDetail",
        name: "wrongquestionbookDetail",
        component: resolve => require(['@/pages/study/questionbook/detail.vue'], resolve),
        meta:{
            title:'错题本',
        }
    },
    { //收藏
        path: "/collection",
        name: "collection",
        component: resolve => require(['@/pages/study/exerciseCollection/index.vue'], resolve),
        meta:{
            title:'收藏',
        }
    },
    { //收藏 -- 习题解析
        path: "/analysis",
        name: "analysis",
        component: resolve => require(['@/pages/study/exerciseCollection/analysis.vue'], resolve),
        meta:{
            title:'收藏',
        }
    },
    { //做题记录
        path: "/questionRecord",
        name: "questionRecord",
        component: resolve => require(['@/pages/study/questionRecord/questionRecord.vue'], resolve),
        meta:{
            title:'做题记录',
        }
    },
    { //做题记录 -- 答题三级页面
        path: "/questionRecordDetail",
        name: "questionRecordDetail",
        component: resolve => require(['@/pages/study/questionRecord/detail.vue'], resolve),
        meta:{
            title:'做题记录',
        }
    },
    {
        path: "/rankingList",
        name: "rankingList",
        component: resolve => require(['@/pages/study/rankingList/rankingList.vue'], resolve),
        meta:{
            title:'答题排行榜',
        }
    },
    {
        path: "/directionFirst",
        name: "directionFirst",
        component: resolve => require(['@/pages/study/studyDirection/directionFirst.vue'], resolve),
        meta:{
            title:'学习方向',
        }
    },
    { //选择学习方向页面
        path: "/directionList/:from?",
        name: "directionList",
        component: resolve => require(['@/pages/study/studyDirection/directionList.vue'], resolve),
        meta:{
            title:'学习方向',
        }
    },
    {
        path: "/directionTab",
        name: "directionTab",
        component: resolve => require(['@/pages/study/studyDirection/directionTab.vue'], resolve),
        meta:{
            title:'学习方向',
        }
    },
    { //章节练习
        path: "/chapterExercises",
        name: "chapterExercises",
        component: resolve => require(['@/pages/study/chapterExercises/index.vue'], resolve),
        meta:{
            title:'章节练习',
        }
    },
    { //章节练习 -- 答题三级页面
        path: "/chapterExercisesDetail",
        name: "chapterExercisesDetail",
        component: resolve => require(['@/pages/study/chapterExercises/chapterExercisesDetail.vue'], resolve),
        meta:{
            title:'章节练习',
        }
    },
    {
        path: "/examinationHeader",
        name: "examinationHeader",
        component: resolve => require(['@/pages/study/examinationHeader/examinationHeader.vue'], resolve),
    },
    { //答题报告
        path: "/answerReport",
        name: "answerReport",
        component: resolve => require(['@/pages/study/answerReport/answerReport.vue'], resolve),
        meta:{
            title:'答题报告',
        }
    },
    { //错题解析
        path: "/questionParsing",
        name: "questionParsing",
        component: resolve => require(['@/pages/study/answerReport/questionParsing.vue'], resolve),
        meta:{
            title:'错题解析',
        }
    },
    {
        path: "/answerCard",
        name: "answerCard",
        component: resolve => require(['@/pages/study/answerCard/answerCard.vue'], resolve),
        meta:{
            title:'答题卡',
        }
    },
    { //学习-导航
        path: "/learning/:from?",
        name: "learning",
        component: resolve => require(['@/pages/studyCenter/studyCenter.vue'], resolve),
        meta:{
            title:'学习中心',
        }
    },
    {
        path: "/mycourse",
        name: "mycourse",
        component: resolve => require(['@/pages/studyCenter/mycourse/mycourse.vue'], resolve),
        meta:{
            title:'我的课程',
        }
    },
    {
        path: "/meallist/:id/:source",
        name: "meallist",
        component: resolve => require(['@/pages/studyCenter/mealist/mealist.vue'], resolve),
        meta:{
            title:'套餐包含课包列表',
        }
    },
    {
        path: "/list",
        name: "list",
        component: resolve => require(['@/pages/studyCenter/mealist/list.vue'], resolve),
        meta:{
            title:'直播课包列表',
        }
    },
    {
        path: "/zhibo",
        name: "zhibo",
        component: resolve => require(['@/pages/livePage/index.vue'], resolve),
        meta:{
            title:'直播',
        }
    },
    // {
    //     path: "/zhibo/:id/:channelId/:coursePackageId/:courseId/:state",
    //     name: "zhibo",
    //     component: resolve => require(['@/pages/livePage/index.vue'], resolve),
    //     meta:{
    //         title:'直播',
    //     }
    // },
    {
        path: "/mylive",
        name: "mylive",
        component: resolve => require(['@/pages/studyCenter/mylive/mylive.vue'], resolve),
        meta:{
            title:'直播列表',
        }
    },
    { // 直播课程列表
        path: "/liveCurriculum",
        name: "liveCurriculum",
        component: resolve => require(['@/pages/studyCenter/mylive/liveCurriculum.vue'], resolve),
        meta:{
            title:'直播课程列表',
        }
    },
    {// 课程答疑
        path: "/curriculumAnswer",
        name: "curriculumAnswer",
        component: resolve => require(['@/pages/studyCenter/curriculumAnswer/curriculumAnswer.vue'], resolve),
        meta:{
            title:'课程答疑',
        }
    },
    {// 课程答疑--详情
        path: "/curriculumAnswerDetail",
        name: "curriculumAnswerDetail",
        component: resolve => require(['@/pages/studyCenter/curriculumAnswer/detail.vue'], resolve),
        meta:{
            title:'课程答疑详情',
        }
    },
    {// 课程答疑
        path: "/noteList",
        name: "noteList",
        component: resolve => require(['@/pages/studyCenter/note/noteList.vue'], resolve),
        meta:{
            title:'课程答疑',
        }
    },
    {// 课程答疑--详情
        path: "/noteDetail",
        name: "noteDetail",
        component: resolve => require(['@/pages/studyCenter/note/detail.vue'], resolve),
        meta:{
            title:'课程答疑详情',
        }
    },


    { //我的
        path: "/my",
        name: "my",
        component: resolve => require(['@/pages/me/me.vue'], resolve),
        meta:{
            title:'我的',
        }
    },
    { //订单列表
        path: "/orderlist",
        name: "orderlist",
        component: resolve => require(['@/pages/me/orderlist.vue'], resolve),
        meta:{
            title:'订单列表',
        }
    },
    { //订单详情
        path: "/orderinfo",
        name: "orderinfo",
        component: resolve => require(['@/pages/me/orderinfo.vue'], resolve),
        meta:{
            title:'订单详情',
        }
    },
    {
        path: "/edituser",
        name: "edituser",
        component: resolve => require(['@/pages/me/edituser.vue'], resolve),
        meta:{
            title:'修改信息',
        }
    },
    { //消息页面
        path: "/message",
        name: "message",
        component: resolve => require(['@/pages/me/message.vue'], resolve),
        meta:{
            title:'消息',
        }
    },
    {  //意见反馈
        path: "/feedback",
        name: "feedback",
        component: resolve => require(['@/pages/me/feedback.vue'], resolve),
        meta:{
            title:'意见反馈',
        }
    },
    {
        path: "/problem",
        name: "problem",
        component: resolve => require(['@/pages/me/problem.vue'], resolve),
        meta:{
            title:'常见问题',
        }
    },
    {
        path: "/me/detail",
        name: "medetail",
        component: resolve => require(['@/pages/me/detail.vue'], resolve),
        meta:{
            title:'常见问题',
        }
    },
    {
        path: "/orderdetail",
        name: "orderdetail",
        component: resolve => require(['@/pages/orderpay/orderdetail.vue'], resolve),
        meta:{
            title:'订单信息',
        }
    },
    {
        path: "/orderstate",
        name: "orderstate",
        component: resolve => require(['@/pages/orderpay/orderstate.vue'], resolve),
        meta:{
            title:'支付',
        }
    },
    {
        path: "/address",
        name: "address",
        component: resolve => require(['@/pages/orderpay/address.vue'], resolve),
        meta:{
            title:'地址',
        }
    },
    {
        path: "/coupon",
        name: "coupon",
        component: resolve => require(['@/pages/orderpay/coupon.vue'], resolve),
        meta:{
            title:'优惠券',
        }
    },
    {
        path: "/order/agreement",
        name: "agreement",
        component: resolve => require(['@/pages/orderpay/agressment.vue'], resolve),
        meta:{
            title:'课程协议',
        }
    },
    {  //设置
        path: "/set",
        name: "set",
        component: resolve => require(['@/pages/me/set.vue'], resolve),
        meta:{
            title:'设置',
        }
    },
    {  //设置
        path: "/changephone",
        name: "changephone",
        component: resolve => require(['@/pages/me/changephone.vue'], resolve),
        meta:{
            title:'设置',
        }
    },
    {  //注销
        path: "/destroy",
        name: "destroy",
        component: resolve => require(['@/pages/me/destroy.vue'], resolve),
        meta:{
            title:'注销',
        }
    },
    {  //我的积分
        path: "/integral",
        name: "integral",
        component: resolve => require(['@/pages/me/integral.vue'], resolve),
        meta:{
            title:'积分',
        }
    },
    {  //领课-引流页面
        path: "/fuli",
        name: "fuli",
        component: resolve => require(['@/pages/fuli/index.vue'], resolve),
        meta:{
            title:'领取课程福利',
        }
    },
    {  //我要兑换
        path: "/exchange",
        name: "exchange",
        component: resolve => require(['@/pages/me/exchange.vue'], resolve),
        meta:{
            title:'我要兑换',
        }
    },


];




const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    to.meta.keepAlive = false;
    if(to.meta.title){
        document.title = to.meta.title
    }else{
        document.title = '萨恩课堂-中国会计网'
    }

    next();
});

export default router;
