import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import studyList from './modules/study';
import userInfo from './modules/saEnUserInfo';
import video from './modules/video';
import selectClass from './modules/selectClass';
import studyDirection from './modules/studyDirection';

Vue.use(Vuex)

export default new Vuex.Store({
 modules:{
   studyList,
   userInfo,
   video,
   selectClass,
   studyDirection
 },
 plugins: [createPersistedState()],
})