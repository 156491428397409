export default {
    state: {
        firstId:'137', //选择学习方向id-如：初级会计师137 中级会计师138
        learningName:'选择学习方向', //选择学习方向名称-如：初级会计师 中级会计师
    },
    getters: {
        getFirstId: function(state) {
            return state.firstId
        },
        getLearningName: function(state) {
            return state.learningName
        }
    },
    mutations: {
        setFirstId(state,res){
            state.firstId = res
            localStorage.setItem('firstId',res);
        },
        setFirstId(state,res){
            state.learningName = res
            localStorage.setItem('learningName',res);
        },
    }
};