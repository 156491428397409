export default {
    state: {
        currentVid:'',
    },
    getters: {
        getCurrentVid: function(state) {
            return state.currentVid
        }
    },
    mutations: {
        
    }
};
